import React, {useState} from 'react'
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import useProfile from '../Auth/useProfile';


export default function Sidebar(props) {
  const history = useHistory();
  const { userProfile } = useProfile();
  const [shrinkStatus, setShrinkStatus] = useState(false);
  
  const changeNavbarType = event => {
    // 👇️ toggle shrink navbar state on click
    setShrinkStatus(current => !current);
  };

  const getUserLoguut = async function () {
    return fetch(`${process.env.REACT_APP_NODE_API}/api/users/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': !_.isUndefined(userProfile) && !_.isNull(userProfile) ? `Bearer ${userProfile.token}` : null,
      },
    })
    .then((response) => response.json())
    .then(responseJson => {
        if (responseJson.status === 'success') {
          props.setUserProfile(null)
          localStorage.clear();
          history.push ('/');
        } else {
          //notify.show(responseJson.message, "error", 5000);
          props.setUserProfile(null)
          localStorage.clear();
          history.push ('/');
        }

    })
    .catch( (error) => {
      console.log(error)
    })
  }  

  return (
    <div className={"d-flex flex-column flex-shrink-0 sidebar bg-dark position-relative " + (props.isHiddenNavbar ? 'collapsed' : shrinkStatus ? 'shrink-sidebar': '')} >
      <div>
        <a href="/dashboard" className="navbar-brand sidebar-brand">
          <img src="/images/betsperts-logo.png" alt="betsperts-logo" className="main-logo"/>
          <img src="/images/betsperts-mini-logo.png" alt="betsperts-logo" className="d-none mini-logo"/>
        </a>
        <button className="navbar-aside-toggler" data-bs-toggle="collapse" data-bs-target="#sidenavCollapse" aria-controls="sidenavCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={changeNavbarType}/>
        <div className="nav nav-pills nav-vertical card-navbar-nav flex-column mb-auto">
          <div className="nav-item">
            <a className="nav-link active" href="/dashboard">
              <img className="nav-link-icon" src="/images/icons/Overview.png" alt="overview-icon"/>
              <span className="nav-title">Overview</span>
            </a>              
          </div>
          <div className="nav-item">
            <a className="nav-link" href="/news">
              <img className="nav-link-icon" src="/images/icons/Post-News.png" alt="overview-icon"/>
              <span className="nav-title">Post News</span>
            </a>              
          </div>
          <div className="nav-item">
            <a className="nav-link" href="/dashboard">
              <img className="nav-link-icon" src="/images/icons/Post-News.png" alt="overview-icon"/>
              <span className="nav-title">3rd Option</span>
            </a>              
          </div>
          <div className="nav-item">
            <a className="nav-link" href="/dashboard">
              <img className="nav-link-icon" src="/images/icons/Post-News.png" alt="overview-icon"/>
              <span className="nav-title">4th Option</span>
            </a>              
          </div>
        </div>
        
        <ul className="position-relative nav nav-vertical nav-pills">
          <li className="nav-item">
            <a href="/settings" className="nav-link">
              <img className="nav-link-icon" src="/images/icons/Settings.png" alt="Settings-icon"/>
              <span className="nav-title">Settings</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/" className="nav-link">
              <img className="nav-link-icon" src="/images/icons/Help.png" alt="Help-icon"/>
              <span className="nav-title">Help</span>
            </a>
          </li>
          <li className="nav-item text-center mt-3 mb-5">
            <button className="danger-button logout-btn" onClick={() => getUserLoguut()}>
              <img className="nav-link-icon" src="/images/icons/Logout.png" alt="Help-icon"/>
              <span className="btn-title ms-2">Logout</span>
            </button>
          </li>
        </ul>
      </div>  
    </div>
  );
};
