import React from "react";

export default function Loader() {
    return (
        <React.Fragment>

            <div className="cmn-loader">
                {/* <img src={`/images/loader.svg`} alt="" /> */}
                <img src={`/images/loader-new.svg`} alt="" />
            </div>

        </React.Fragment>
    )
}