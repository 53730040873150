import React, {useState, useEffect} from "react";
import { Router, Route, Switch} from 'react-router-dom';
import Notifications from 'react-notify-toast';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

import {Login} from './components/Login/Login';
import Dashboard from "./components/Dashboard/Dashboard";
import Settings from "./components/Settings/Settings";
import Sidebar from "./components/Sidebar/Sidebar";
import Navigation from "./components/Navigation/Navigation";
import PostNews from "./components/News/PostNews";
import useProfile from './components/Auth/useProfile';

function App() {
  const history = useHistory();
  const { userProfile, setUserProfile } = useProfile();
  const [isHidden, setIsHidden] = useState(false);
  const handleClick = event => {
    // 👇️ toggle navbar state on click
    setIsHidden(current => !current);
  }


  useEffect(() => {
    // change background color with a login screen
    document.body.style.background = !_.isUndefined(userProfile) && !_.isNull(useProfile) && !_.isEmpty(userProfile) ? '#F5F5F5' : '#0C1116';
  });
  
  // if(_.isUndefined(userProfile) || _.isNull(useProfile) || _.isEmpty(userProfile)) {
  //   return (
  //     <main> 
  //       <Notifications />
  //       <Login setUserProfile={setUserProfile} />
  //     </main>   
  //   )  
  // } 

    return (
      <main>
        <div className="wrapper">
            <Notifications />
            {
                (!_.isUndefined(userProfile) &&  !_.isNull(userProfile) && !_.isEmpty(userProfile)) && !(window.location.pathname).includes("news") &&
              <Sidebar isHiddenNavbar={isHidden} setUserProfile={setUserProfile}/>
            }
            <div className="main">
            {(!_.isUndefined(userProfile) &&  !_.isNull(userProfile) && !_.isEmpty(userProfile)) && 
              <Navigation handleClick={handleClick}/>
             } 
              <div className="content">
              <Router history={history}>
                <Switch>
                  <Route exact path="/">
                    <Login setUserProfile={setUserProfile}/>
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard setUserProfile={setUserProfile}/>
                  </Route>
                  <Route path="/settings">
                    <Settings setUserProfile={setUserProfile}/>
                  </Route>
                  <Route exact path="/news">
                    <PostNews setUserProfile={setUserProfile}/>
                  </Route>
                  <Route path="/news/:id">
                    <PostNews setUserProfile={setUserProfile}/>
                  </Route>
                </Switch>
              </Router>
              </div>
            </div>
        </div>
        {/* <ul>
            <li>
              <Link to="/">To Home</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
        </ul>  */}
          {/* <Routes>
            <Route path='/dashboard' element={<Dashboard />} />
          </Routes>
          <Routes>
            <Route path='/settings' element={<Settings />} />
          </Routes>       */}
      </main>
    );
}

export default App;
