import React,  {useState, Fragment} from 'react';
import { useHistory } from "react-router-dom";
import _ from 'lodash';
import {notify} from 'react-notify-toast';

import useProfile from '../Auth/useProfile';
import Loader from "../Loader/Loader";


export default function Settings(props) {
    const { userProfile } = useProfile();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [viewOld, setViewOld] = useState(false);
    const [viewnew, setViewNew] = useState(false);
    const [viewConf, setViewConf] = useState(false);
    const [allValues, setAllValues] = useState({oldPassword: '', newPassword: '', confirmPassword: ''});
    const [formErrors, setFormErrors] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });
    const [oldPasswordValid, setOldPasswordValid] = useState(false)
    const [newPasswordValid, setNewPasswordValid] = useState(false)
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false)

    // Whenever an input changes value, change the corresponding state variable
    const handleInputChange = (e) =>{
        e.preventDefault();
        const target = e.target;
        setAllValues({ ...allValues, [target.name]: target.value });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true)
        // Verify that the passwords match
        const {oldPassword, newPassword, confirmPassword} = allValues;
        let fieldValidationErrors = formErrors;

         if (oldPassword.length < 8){
            setOldPasswordValid(true)
            fieldValidationErrors.oldPassword = 'Please enter your current password';
        } else {
            setOldPasswordValid(false)
            fieldValidationErrors.oldPassword = '';
        }   

        if (newPassword.length < 8) {
            setNewPasswordValid(true)
            fieldValidationErrors.newPassword = 'Password must be at least 8 characters long.';
        } else {
            setNewPasswordValid(false);
            fieldValidationErrors.newPassword = '';
        }

        if ((confirmPassword.length < 8) || (newPassword !== confirmPassword)) {
            setConfirmPasswordValid(true)
            fieldValidationErrors.confirmPassword = 'Your password does not match with new password.';
        } else {
            setConfirmPasswordValid(false);
            fieldValidationErrors.confirmPassword = '';
        }
        
        setFormErrors(fieldValidationErrors);

        if (!_.isEmpty(fieldValidationErrors.oldPassword) || !_.isEmpty(fieldValidationErrors.newPassword) || !_.isEmpty(fieldValidationErrors.confirmPassword)){
            setIsLoading(false)
            return false;
        } else {
            const { oldPassword, newPassword } = allValues;
            const params = {
                oldPassword: oldPassword,
                password: newPassword
            }
            await fetch(`${process.env.REACT_APP_NODE_API}/api/users/changePassword`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': !_.isUndefined(userProfile) && !_.isNull(userProfile) ? `Bearer ${userProfile.token}` : null,
                },
                body: JSON.stringify(params)
            })
            .then((res) => res.json())
            .then((responseJson) => {  
                setIsLoading(false);
                setOldPasswordValid(false);
                setNewPasswordValid(false);
                setConfirmPasswordValid(false);

                if (responseJson.status === 'success') {
                    notify.show('Your password changed successfully', "success", 5000);
                    setAllValues({ ...allValues, oldPassword: '', newPassword:'', confirmPassword:'' });
                    setFormErrors({ ...formErrors, oldPassword: '', newPassword:'', confirmPassword:'' });
                } else {
                    if (responseJson.type === 'current_password') {
                        setOldPasswordValid(true);
                        setFormErrors({...formErrors, oldPassword: responseJson.message});
                    } else if (responseJson.status === 'error') {
                        if (responseJson.response_code === 401) {
                          props.setUserProfile(null)
                          localStorage.clear();
                          history.push ('/');
                        } else {
                            notify.show(responseJson.message, "error", 5000);
                        }
                    }
                }
            }).catch(error => {
                setIsLoading(false);
                console.log(error)
            })

        }
            
        
    }

    const { oldPassword, newPassword, confirmPassword } = allValues;

    return (
        <Fragment>
            {
                isLoading &&
                <Loader />
            }
            <div id="setting-content" className="content px-4 py-3">
                <div className="mt-1 heading">Change Password</div>
                <form className="form-signin mt-3" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <div className="position-relative">
                            <label className="form-label">Enter Current Password</label>
                            <input type={viewOld ? 'text' : 'password'} className="width-400 form-control" name="oldPassword" placeholder="Enter Your Current Password" value={oldPassword} onChange={handleInputChange} />
                            <img src={"/images/icons/" + (viewOld ? 'eye-close.png' : 'eye.png') } className="password-view" alt="password" onClick={() => setViewOld(current => !current)}/>
                        </div>
                            {
                                (oldPasswordValid) &&
                                <div id="emailHelp" className="form-text form-error">{formErrors.oldPassword}</div>
                            }
                    </div>

                    <div className="mb-3">
                        <div className="position-relative">
                            <label className="form-label">Enter New Password</label>
                            <input type={viewnew ? 'text' : 'password'} className="width-400 form-control" name="newPassword" placeholder="Enter Your New Password" value={newPassword} onChange={handleInputChange} />
                            <img src={"/images/icons/" + (viewnew ? 'eye-close.png' : 'eye.png') } className="password-view" alt="password" onClick={() => setViewNew(current => !current)}/>
                        </div>
                            {
                                (newPasswordValid) &&
                                <div id="emailHelp" className="form-text form-error">{formErrors.newPassword}</div>
                            }
                    </div>
                    <div className="mb-3">
                        <div className="position-relative">
                            <label className="form-label">Confirm Password</label>
                            <input type={viewConf ? 'text' : 'password'} className="width-400 form-control" name="confirmPassword" placeholder="Enter Your Confirm Password" value={confirmPassword} onChange={handleInputChange} />
                            <img src={"/images/icons/" + (viewConf ? 'eye-close.png' : 'eye.png') } className="password-view" alt="password" onClick={() => setViewConf(current => !current)}/>
                        </div>
                            {
                                (confirmPasswordValid) &&
                                <div id="emailHelp" className="form-text form-error">{formErrors.confirmPassword}</div>
                            }   
                    </div>
                    <button type="submit" className="btn btn-primary btn-submit mt-2" disabled={(oldPassword.length < 8 || newPassword.length < 8 || confirmPassword.length < 8)}>Save</button>
                </form>
            </div> 
        </Fragment>                 
    )
}    