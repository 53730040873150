import React, { useEffect, useState, useRef, Fragment } from 'react'; 
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select'
import _ from 'lodash';
import Switch from "react-switch";
import {notify} from 'react-notify-toast';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

import Loader from "../Loader/Loader";
import useProfile from '../Auth/useProfile';

export default function PostNews(props) {
    const { id } = useParams();
    const history = useHistory();
    const { userProfile } = useProfile();
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const [newsData, setNewsData] = useState(null);
    const [teamOptions, setTeamOptions] = useState(null);
    const [playerOptions, setPlayerOptions] = useState(null);
    const [playerCount, setPlayerCount] = useState(1);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [fullsourceName, setFullsourceName] = useState('');
    const [sourceName, setSourceName] = useState('');
    const [sourceTime, setSourceTime] = useState('');
    const [sourceLink, setSourceLink] = useState('');
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [fantasyImpact, setFantasyImpact] = useState({status: false, title: '', description: ''});
    const [dynastyImpact, setDynastyImpact] = useState({status: false, title: '', description: ''});
    const [bettingImpact, setBettingImpact] = useState({status: false, title: '', description: ''});
    const [playerIndex, setPlayerIndex] = useState(0)
    const [selectedPlayeroptions, setSelectedPlayeroptions] = useState([])
    const [newStatus, setNewStatus] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [pushNotification, setPushNotification] = useState();
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [publishSave, setPublishSave] = useState(false);


    const handleClose = () => setShowModal(false);

    useEffect(() => {
        if (!_.isUndefined(id) && !_.isNull(id) && !_.isEmpty(id)){
            setIsLoading(true)
            getNewsById();
        }
        getAllTeams();
    }, [newsData]); 

    const getNewsById = async () => {
        await fetch(`${process.env.REACT_APP_NODE_API}/api/news/getNews/`+id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': !_.isUndefined(userProfile) && !_.isNull(userProfile) ? `Bearer ${userProfile.token}` : null,
        },
        })
        .then((res) => res.json())
        .then((responseJson) => {
            setIsLoading(false)
            if (responseJson.status === 'success' && !_.isEmpty(responseJson.data)) {
              if(!_.isUndefined(responseJson.data) && !_.isNull(responseJson.data) && !_.isUndefined(responseJson.data.body) && !_.isEmpty(responseJson.data.body) && !_.isNull(responseJson.data.body)) {                  
                if (_.isNull(newsData) || _.isEmpty(newsData)) {
                      setNewsData(responseJson.data);
                      setNewStatus(responseJson.data.status)
                  }

                  if (!_.isUndefined(responseJson.data.newspriority) && !_.isNull(responseJson.data.newspriority) && !_.isEmpty(responseJson.data.newspriority)) {   
                      setTitle(responseJson.data.newspriority);
                  }
                  if (!_.isUndefined(responseJson.data.fantasyimpact) && !_.isUndefined(responseJson.data.fantasyimpact.title) && !_.isUndefined(responseJson.data.fantasyimpact.description)) {
                      setFantasyImpact(responseJson.data.fantasyimpact)
                  }
                  if (!_.isUndefined(responseJson.data.dynastyimpact) && !_.isUndefined(responseJson.data.dynastyimpact.title) && !_.isUndefined(responseJson.data.dynastyimpact.description)) {
                      setDynastyImpact(responseJson.data.dynastyimpact)
                  }
                  if (!_.isUndefined(responseJson.data.bettingimpact) && !_.isUndefined(responseJson.data.bettingimpact.title) && !_.isUndefined(responseJson.data.bettingimpact.description)) {
                      setBettingImpact(responseJson.data.bettingimpact)
                  }
                  if (!_.isUndefined(responseJson.data.playeroptions) && !_.isEmpty(responseJson.data.playeroptions)) {
                    changePlayerOptions(responseJson.data.playeroptions)
                  }
                  if (!_.isUndefined(responseJson.data.body) && !_.isNull(responseJson.data.body) && !_.isEmpty(responseJson.data.body)) {   

                        let description_array = responseJson.data.body.split(/\n?\n/);
                        let total_length = description_array.length
                        let sourceInfo = description_array[description_array.length - 2];
                        let linkInfo = description_array[description_array.length - 1]
                        setFullsourceName(sourceInfo);
                        setSourceName(sourceInfo.split('|')[0]);
                        setSourceLink(linkInfo)
                        setSourceTime(sourceInfo.split('|')[1])
                        setDescription(description_array.slice(0, -2));

                  } 
              }    
            } else if (responseJson.status === 'error') {
                if (responseJson.response_code === 401) {
                  props.setUserProfile(null)
                  localStorage.clear();
                  history.push ('/');
                } else {
                    notify.show(responseJson.message, "error", 5000);
                }
            }
        }).catch(error => {
            setIsLoading(false)
        })
    } 

    const getAllTeams = async() => {
        await fetch(`${process.env.REACT_APP_SPORTS_DATA_API_URL}/AllTeams?key=${process.env.REACT_APP_SPORTS_DATA_API_KEY}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
        })
        .then((res) => res.json())
        .then((responseJson) => {
            if (!_.isNull(responseJson) && !_.isEmpty(responseJson)){
                // setAllteams(responseJson);
                let teamOption = [];
                _.map(responseJson, (team) => {
                    teamOption.push({value: team.TeamID, label: team.FullName, key: team.Key})
                })  
              setTeamOptions(teamOption);
          }      
        }).catch(error => {
            console.log(error);
        })
    }  

    const changeImpactStatus = feature => event => {
        // 👇️ toggle navbar state on click
        if (feature === 'fantasy'){
            setFantasyImpact({ ...fantasyImpact, status: event});
        } else if (feature === 'dynasty'){
            setDynastyImpact({ ...dynastyImpact, status: event});
        } else {
            setBettingImpact({ ...bettingImpact, status: event});
        }
    }
    
    const addMorePlayer = () => {
        if (playerCount <= 9) {
            setPlayerCount((prevValue) => prevValue + 1)
        } else {
            notify.show('You can add maximum 10 players in one news', "error", 5000);
        }
    }

    const deletePlayer = index => {
        setPlayerCount((prevValue) => prevValue - 1)
        if (!_.isNull(selectedTeam) && !_.isUndefined(selectedTeam[index]) && !_.isNull(selectedTeam[index])) {
            delete selectedTeam[index];
            setSelectedTeam(selectedTeam)
        }    
        if (!_.isNull(selectedTeam) && !_.isUndefined(selectedPlayer[index]) && !_.isNull(selectedPlayer[index])){
            delete selectedPlayer[index];
            setSelectedPlayer(selectedPlayer)
        }
        if (!_.isNull(playerOptions) && !_.isUndefined(playerOptions[index]) && !_.isNull(playerOptions[index])){
            delete playerOptions[index];
            setPlayerOptions(playerOptions)
        }
        if (!_.isEmpty(selectedPlayeroptions) && (selectedPlayeroptions.length >= index)) {
            selectedPlayeroptions.splice(index, 1); 
            setSelectedPlayeroptions(selectedPlayeroptions)
        }    

    }

    const getAllTeamPlayers = async (item) => {  
        fetch(`${process.env.REACT_APP_SPORTS_DATA_API_URL}/PlayersBasic/${item.key}?key=${process.env.REACT_APP_SPORTS_DATA_API_KEY}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
          })
          .then((res) => res.json())
          .then((responseJson) => {
            setIsLoading(false)
            if (!_.isNull(responseJson) && !_.isEmpty(responseJson)){
              let playerOption = [];
              _.map(responseJson, (player) => {
                playerOption.push({value: player.PlayerID, label: player.Name, key: player.Position})
              }) 

              let players = { ...playerOptions };
              players[playerIndex] = playerOption
  
              setPlayerOptions(players);
            }      
        }).catch(error => {
            console.log(error);
        })
    } 

    const draftNews = () => {
        setNewStatus(1)
        setFormSubmit(true)
    }

    const publishNews = () => {
        setNewStatus(2);
        setPublishSave(true);
        setFormSubmit(true)
    }

    const repblishNews = () => {
        if (!fantasyImpact.status && !_.isEmpty(fantasyImpact.title) && !_.isEmpty(fantasyImpact.description))
            setFantasyImpact({ ...fantasyImpact, status: true});

        if (!dynastyImpact.status && !_.isEmpty(dynastyImpact.title) && !_.isEmpty(dynastyImpact.description))
            setDynastyImpact({ ...dynastyImpact, status: true});
        
        if (!bettingImpact.status && !_.isEmpty(bettingImpact.title) && !_.isEmpty(bettingImpact.description))
            setBettingImpact({ ...bettingImpact, status: true});

        setFormSubmit(true)
    }

    useEffect(() => {
        if (formSubmit) {
            handleSubmit()
        }
    }, [newStatus, formSubmit, publishSave, fantasyImpact, dynastyImpact, bettingImpact]) 

    const handleSubmit = async e => {
        // e.preventDefault();
        if (!_.isEmpty(sourceLink) && !validateLinkUrl(sourceLink)) {
            setFormSubmit(false);
            notify.show('Please enter a valid source link', "error", 10000);
            return false;
        }
        if (_.isEmpty(fantasyImpact.title) && _.isEmpty(fantasyImpact.description) && _.isEmpty(dynastyImpact.title) && _.isEmpty(dynastyImpact.description) && _.isEmpty(bettingImpact.title) && _.isEmpty(bettingImpact.description)) {
            setFormSubmit(false);
            notify.show('At least on impact details is required to save news', "error", 5000);
            return false;
        }

        setIsLoading(true);

        let data = {...newsData}
        // let newsDescription = description + {'\n\n'} + sourceName + ' | ' + (!_.isEmpty(sourceTime) ? sourceTime : new Date().toLocaleTimeString("en-US", {timeZone: 'America/New_York'}, 'fr-FR', {hour: '2-digit', minute: '2-digit'}).replace(/(.*)\D\d+/, '$1')) +' ET\n' + sourceLink;
        let newsDescription = `${description}\n\n- ${sourceName} | ${(!_.isEmpty(sourceTime) ? sourceTime : new Date().toLocaleTimeString("en-US", {timeZone: 'America/New_York'}, 'fr-FR', {hour: '2-digit', minute: '2-digit'} +'ET').replace(/(.*)\D\d+/, '$1'))}\n${sourceLink}`
        data.playeroptions = selectedPlayeroptions

        if(_.isUndefined(data.date)) {
            let newdate = new Date().toLocaleString("en-US", {timeZone: 'America/New_York'})
            data.date = new Date(newdate).getTime();
        }

        if(_.isUndefined(data.newstype))
            data.newstype = 1;

        if(_.isUndefined(data.sports))
            data.sports = 'NFL';

        if(_.isUndefined(data.authorname))
            data.authorname = process.env.REACT_APP_NEWS_AUTHOR;

        data.newspriority = title;
        data.body = newsDescription;
        data.status = newStatus
        data.fantasyimpact = fantasyImpact
        data.dynastyimpact = dynastyImpact
        data.bettingimpact = bettingImpact

        if(publishSave) {
            data.publishedat = new Date();
            data.flanotification = pushNotification
        }

        if (newStatus === 2 && !publishSave) {
            data.republishNews = true;
        }

        
        let params = {
            newsFeeds: data
        }
        
        if (!_.isUndefined(id) && !_.isNull(id) && !_.isEmpty(id))
            params['id'] = id

        await fetch(`${process.env.REACT_APP_NODE_API}/api/news/postNews`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': !_.isUndefined(userProfile) && !_.isNull(userProfile) ? `Bearer ${userProfile.token}` : null,
            },
            body: JSON.stringify(params)
        })
        .then((res) => res.json())
        .then((responseJson) => {
            setShowModal(false)
            setFormSubmit(false)
            setIsLoading(false)
            setPublishSave(false)
            if (responseJson.status === 'success' && !_.isEmpty(responseJson.data)) {
                if (_.isUndefined(id) || _.isNull(id) || _.isEmpty(id))
                {
                    setNewsData(null);
                    notify.show('News added successfully', "success", 5000);
                    history.push(`/news/${responseJson.data._id}`);
                    window.location.reload();
                } else {
                    setNewsData(null);
                    getNewsById()
                    notify.show('News updated successfully', "success", 5000);

                }
            } else if (responseJson.status === 'error') {
                if (responseJson.response_code === 401) {
                  props.setUserProfile(null)
                  localStorage.clear();
                  history.push ('/');
                } else {
                    notify.show(responseJson.message, "error", 5000);
                }
            }  
        })
        .catch(error => {
            console.log(error);
        })
    }  
    
    useEffect(() => {
        console.log('playerOptions->', playerOptions)

    }, [playerOptions]); 

    useEffect(() => {
        // console.log('selected team-->', selectedTeam, playerIndex)
    }, [playerIndex]);

    useEffect(() => {
        if (!_.isNull(selectedTeam) && !_.isUndefined(selectedTeam[playerIndex])){
            getAllTeamPlayers(selectedTeam[playerIndex]);
        }
    }, [selectedTeam, playerCount, selectedPlayeroptions])  

    useEffect(() => {
        if (!_.isEmpty(title) && !_.isEmpty(description) && !_.isEmpty(sourceName) && !_.isEmpty(sourceLink) && ((!_.isEmpty(fantasyImpact.title) && !_.isEmpty(fantasyImpact.description)) || (!_.isEmpty(dynastyImpact.title) && !_.isEmpty(dynastyImpact.description)) || (!_.isEmpty(bettingImpact.title) && !_.isEmpty(bettingImpact.description))))
            setDisableSubmit(false);
        else 
            setDisableSubmit(true);

    }, [title, description, sourceName, sourceLink, playerIndex, selectedPlayeroptions, playerCount, newStatus, fantasyImpact, dynastyImpact, bettingImpact])  
    
    const changeSelectTeam = async (item, index) => {
        setIsLoading(true)
        if (item.value !== '') {
            setSelectedTeam((prev) => ({
                ...prev,
                [index]:  item,
            }));
            setPlayerIndex(index)
        } else {
            setPlayerIndex(0)
            if (!_.isUndefined(selectedTeam[index])) {
                delete selectedTeam[index];
                setSelectedTeam(selectedTeam);
            }
        }
        if (!_.isNull(selectedPlayer) && !_.isUndefined(selectedPlayer[index])) {
            delete selectedPlayer[index];
            setSelectedPlayer(selectedPlayer);
        }

    }

    const changeSelectedPlayer = async (item, index) => {
        if (item.value !== '') {
            setSelectedPlayer((prev) => ({
                ...prev,
                [index]:  item,
            }));
            let playerOptions = selectedPlayeroptions;
            playerOptions[index] = {playerid: item.value, playername: item.label, position: item.key, teamid: selectedTeam[index].value, teamname: selectedTeam[index].label, key:selectedTeam[index].key};
            setSelectedPlayeroptions(playerOptions)

        } else {
            if (!_.isUndefined(selectedPlayer[index])) {
                delete selectedPlayer[index];
                setSelectedPlayer(selectedPlayer); 

            }
            if (selectedPlayeroptions.indexOf(index) > -1){
                selectedPlayeroptions.splice(index, 1); 
                setSelectedPlayeroptions(selectedPlayeroptions)
            }
        }    
    }

    const changePlayerOptions = async (items) => {
        setSelectedPlayeroptions(items);
        if (items.length > 0) {
            setPlayerCount((prevValue) => items.length)
        }
        const promises = items.map((item, index) => {
            return new Promise(resolve => {
                if (index == 0) {
                    getAllTeamPlayers({value: item.teamid, label: item.teamname, key: item.key});
                }
                setTimeout(() => {
                    setPlayerIndex(index)
                    setSelectedTeam((prev) => ({
                        ...prev,
                        [index]:  {value: item.teamid, label: item.teamname, key: item.key},
                    }));
                    setSelectedPlayer((prev) => ({
                        ...prev,
                        [index]:  {value: item.playerid, label: item.playername, key: item.position},
                    }));
                }, 1000);
            });
        });
    }

    const validateLinkUrl = (url) => {
        if (url !== undefined || url !== '') {
            var regExp = ("((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)")  //new regEx
            var match = url.match(regExp);
            if (match) {
                return true;
            }
            else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <Fragment>
            {
                isLoading &&
                <Loader />
            }
        <div className="container-fluid px-3 py-3" id="create-form">
            <div className="row">
                <div className="col-md-8 create-new-content">
                    <div className="py-1 heading">Create News</div>
                    {/* <form > */}
                        <div className="mb-3">
                            <input type="text" className="w-100 form-control" placeholder="News Title" value={title} required onChange={(e) => setTitle(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <textarea type="text" rows={3} className="w-100 form-control"  placeholder="News Content" value={description} required onChange={(e) => setDescription(e.target.value)}/>
                        </div>
                        <div className="row mb-1">
                            <div className="col">
                                <input type="text" className="w-100 form-control" placeholder="Source Name" value={sourceName} required onChange={(e) => setSourceName(e.target.value)}/>
                            </div>   
                            <div className="col">  
                                <input type="text" ref={inputRef} className="w-100 form-control" placeholder="Source Link" value={sourceLink} required onChange={(e) => setSourceLink(e.target.value)}/>
                             </div>   
                        </div>
                        <div className="mt-3 mb-2 sub-heading">
                            Player(s) in the news
                        </div>
                        {
                            [...Array(playerCount)].map((elementInArray, index) => (
                                    <div className="row mb-3" key={index}>
                                        <div className="col-md-4">  
                                        <Select 
                                            data-key={index}
                                            classNames={{
                                            control: () =>
                                                'react-form-select w-100',
                                            }}  
                                            placeholder="Team Name" 
                                            options={teamOptions} 
                                            defaultValue={{ label: "Team Name", value: '', key: '' }} 
                                            value={!_.isNull(selectedTeam) && !_.isUndefined(selectedTeam[index]) ? selectedTeam[index] : null}
                                            onChange={(selectedValue) => { changeSelectTeam(selectedValue, index) }} 
                                            styles={{
                                                option: (base) => ({
                                                    ...base,
                                                    fontSize: 12,
                                                    height: '100%',
                                                }),
                                            }}
                                        />
                                        </div> 
                                        <div className="col-md-4">
                                        <Select 
                                            classNames={{
                                            control: () =>
                                                'react-form-select w-100',
                                            }} 
                                            placeholder="Player Name" 
                                            options={!_.isNull(playerOptions) && !_.isUndefined(playerOptions[index] && !_.isNull(playerOptions[index])) ? playerOptions[index] : null} 
                                            defaultValue={{ label: "Player Name", value: '' }} 
                                            value={!_.isNull(selectedPlayer) && !_.isUndefined(selectedPlayer[index]) && !_.isNull(selectedPlayer[index]) ? selectedPlayer[index] : null}
                                            onChange={(selectedValue) => changeSelectedPlayer(selectedValue, index)} 
                                            styles={{
                                                option: (base) => ({
                                                    ...base,
                                                    fontSize: 12,
                                                    height: '100%',
                                                }),
                                            }}
                                            isDisabled={((_.isNull(selectedTeam) || _.isUndefined(selectedTeam[index]) || _.isNull(selectedTeam[index])))}
                                        />
                                    </div>  
                                    <div className="col-3">  
                                        <input type="text" value={(!_.isNull(selectedPlayer) && !_.isUndefined(selectedPlayer[index]) && !_.isNull(selectedPlayer[index]) ? selectedPlayer[index].key : '')}  className="w-100 form-control" placeholder="Position" readOnly/>
                                    </div> 
                                    {
                                        index !== 0 &&
                                        <div className="col">
                                            <div style={{cursor:'pointer'}} onClick={() => deletePlayer(index)}><img src="/images/icons/ic-delete.svg" alt="delete-icon" /></div>
                                        </div>
                                    }
                                    
                                </div>
                               ) 
                               )}
                        <div className="d-flex align-items-center justify-content-end primary-text sub-text" onClick={addMorePlayer}><img src="/images/icons/ic-add.png" className="add-more-icon"  alt="ic-add" />Add Player</div>
                        <div className="mt-3 mb-2 sub-heading d-flex align-items-center">
                            Fantasy Impact
                            {
                                newStatus !== 2 &&
                                <Switch className="ms-2" onChange={changeImpactStatus('fantasy')} checked={fantasyImpact.status} uncheckedIcon={false} checkedIcon={false} height={18} width={30}/>
                            }
                        </div>
                        <div className="mb-3">
                            <input type="text" className="w-100 form-control" placeholder="News Title" required value={fantasyImpact.title} disabled={(!fantasyImpact.status && (newStatus !== 2))} onChange={(e) => setFantasyImpact({ ...fantasyImpact, title: e.target.value })}/>
                        </div>
                        <div className="mb-3 mb-1">
                            <textarea type="text" rows={3} className="w-100 form-control"  placeholder="News Content" required value={fantasyImpact.description} disabled={((newStatus !== 2) && (!fantasyImpact.status))} onChange={(e) => setFantasyImpact({ ...fantasyImpact, description: e.target.value })}/>
                        </div>
                        <div className="mt-3 mb-2 sub-heading d-flex align-items-center">
                            Dynasty Impact
                            {
                                newStatus !== 2 &&
                                <Switch className='ms-2' onChange={changeImpactStatus('dynasty')} checked={dynastyImpact.status} uncheckedIcon={false} checkedIcon={false} height={18} width={30}/>
                            }    
                        </div>
                        <div className="mb-3">
                            <input type="text" className="w-100 form-control" placeholder="News Title" required value={dynastyImpact.title} disabled={((newStatus !== 2) && (!dynastyImpact.status))} onChange={(e) => setDynastyImpact({ ...dynastyImpact, title: e.target.value })}/>
                        </div>
                        <div className="mb-1">
                            <textarea type="text" rows={3} className="w-100 form-control" placeholder="News Content" required value={dynastyImpact.description} disabled={((newStatus !== 2) && (!dynastyImpact.status))} onChange={(e) => setDynastyImpact({ ...dynastyImpact, description: e.target.value })}/>
                        </div>
                        <div className="mt-3 mb-3 sub-heading d-flex align-items-center">
                            Betting Impact
                            {
                                newStatus !== 2 &&    
                                <Switch className='ms-2' onChange={changeImpactStatus('betting')} checked={bettingImpact.status} uncheckedIcon={false} checkedIcon={false} height={18} width={30}/>
                            }    
                        </div>
                        <div className="mb-3">
                            <input type="text" className="w-100 form-control" placeholder="News Title" required value={bettingImpact.title}  disabled={((newStatus !== 2) && (!bettingImpact.status))} onChange={(e) => setBettingImpact({ ...bettingImpact, title: e.target.value })}/>
                        </div>
                        <div className="mb-5">
                            <textarea type="text" rows={3} className="w-100 form-control" placeholder="News Content" required value={bettingImpact.description} disabled={((newStatus !== 2) && (!bettingImpact.status))} onChange={(e) => setBettingImpact({ ...bettingImpact, description: e.target.value })}/>
                        </div>

                        { ((newStatus === 0) || (newStatus === 1)) ?
                            <div className="col-auto mb-3">
                                <button type="submit" className="btn btn-default btn-submit me-3" onClick={draftNews} disabled={disableSubmit}>Save Draft</button>
                                <button type="submit" className="btn btn-primary btn-submit" disabled={disableSubmit} onClick={() => setShowModal(true)}>Publish News</button>
                            </div>
                        :
                           
                            <div className="col-auto mb-3"><button type="submit" className="btn btn-primary btn-submit" onClick={repblishNews} >Republish</button></div>
                        }       
                        
                    {/* </form> */}
                </div>
                <div className="col-md-4 news-sidebar">
                    {
                        (!_.isUndefined(newsData) && !_.isEmpty(newsData) && !_.isUndefined(newsData.newstype) && (newsData.newstype === 2)) && 
                        <div className="col-12">
                            <div className="heading">Source: FLA Breaking News</div>
                            <div className="mt-3 news-box p-3">
                                <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                        <div className="me-1"><img src="/images/icons/FLA_News_icon.svg" alt="news-logo" /></div>
                                        <div className="me-1 heading">{title}</div>
                                        <img src="/images/icons/ic-visiblity.png" className="icon-start" alt="ic-visiblity" />
                                        <div className="date-text ms-1">{ moment(newsData.date).fromNow()}</div>
                                    </div>
                                    <div className="ms-auto d-flex align-items-center">
                                        <span className="league-name">NFL</span>
                                    </div>
                                </div>
                                <div className="news-content mt-2">
                                    {description}
                                </div>
                                <div className="news-content mt-3">
                                {fullsourceName}
                                </div>
                                <div className="social-link text-break">{sourceLink}</div>

                                <div className="mt-2 d-flex align-items-center d-none">
                                    <img src="/images/icons/ic_like.png" className="action-icon"  alt="ic-like"/>
                                    <span className="like-count ms-1">1</span>
                                    <img src="/images/icons/ic_visibility.png" className="action-icon ms-3"  alt="ic-visiblity" />
                                </div>
                            </div>
                        </div>
                    }           
                </div>
            </div>

            <div>
                <Modal
                    show={showModal}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                    >
                    <Modal.Body>
                        <div className="py-4 px-5">
                            <div className="model-heading mb-3">Are you sure?</div>
                            <div className="modal-message">Publishing news will send notification on FLA App. </div>
                        </div>
                        <div className="text-center d-flex">
                            <Button variant="primary" className="btn-confirm" onClick={() => { setPushNotification(true); publishNews()}}>
                                Publish with FLA Push Notification
                            </Button>
                            <Button variant="primary" className="btn-confirm ms-3" onClick={() => {setPushNotification(false); publishNews()}}>
                                Publish without FLA Push Notification
                            </Button>
                            <Button variant="secondary" className="btn-default btn-submit ms-3" onClick={handleClose}>
                                Cancel
                            </Button>
                        </div> 
                        <div className="mt-3 d-flex align-items-center justify-content-center modal-message mb-3">
                            <img src="/images/icons/notice.svg" alt="notifictaion-icon"/>
                            You cannot undo published news
                        </div>   
                    </Modal.Body>
                </Modal>
            </div>
        </div>
        </Fragment> 
    )
}    