import React,  {useEffect, useState, Fragment} from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select'
import _ from 'lodash';
import moment from 'moment-timezone';
import { useHistory } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';

import useProfile from '../Auth/useProfile';
import Loader from "../Loader/Loader";

import "react-datepicker/dist/react-datepicker.css";
import {notify} from 'react-notify-toast';

export default function Dashboard(props) {
  const history = useHistory();
  const { userProfile } = useProfile();
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear() - (new Date().getMonth() > 0 ? 0 : 1), (new Date().getMonth() - 1 + 12) % 12, 1));
  const [endDate, setEndtDate] = useState(new Date());
  const [teamOptions, setTeamOptions] = useState(null);
  const [playerOptions, setPlayerOptions] = useState(null);
  const [newsRecords, setNewsRecords] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [sortBy, setSortBy] = useState({ date: 'DESC', displayName: 'ASC'});
  const [showModal, setShowModal] = useState(false);
  const [newsId, setNewsId] = useState('');

  const handleClose = () => setShowModal(false);

  function sort_by(field, reverse, primer) {
    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}

  const getAllNews = async () => {
    setIsLoading(true);
    var date1 = new Date(startDate).valueOf();
    var date2 = new Date(endDate).valueOf();
    var params = {
      startDate: Date.parse(moment.tz(date1, "America/New_York").format()),
      endDate: Date.parse(moment.tz(date2, "America/New_York").format()),
    }
    if (!_.isNull(selectedTeam)) {
      params['teamId'] = selectedTeam.value
    }
    if (!_.isNull(selectedPlayer)) {
      params['playerId'] = selectedPlayer.value
    }
   
    await fetch(`${process.env.REACT_APP_NODE_API}/api/news/getAllNews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': !_.isUndefined(userProfile) && !_.isNull(userProfile) ? `Bearer ${userProfile.token}` : null,
      },
      body: JSON.stringify(params)
    })
    .then((res) => res.json())
    .then((responseJson) => {
        setIsLoading(false)
        console.log('responseJson.data-->', responseJson)
        if (responseJson.status === 'success') {
          // if (!_.isUndefined(responseJson.data.length) && responseJson.data.length > 0) {
          //   setNewsRecords(responseJson);
          // } else {
            setNewsRecords(responseJson);
          
          
        } else if (responseJson.status === 'error') {
          if (responseJson.response_code === 401) {
            props.setUserProfile(null)
            localStorage.clear();
            history.push ('/');
          } else {
            notify.show(responseJson.message, "error", 5000);
          }
        }  
    }).catch(error => {
        console.log('ssss,==>', error);
    })
  }; 
  
  async function getAllTeams() {
    await fetch(`${process.env.REACT_APP_SPORTS_DATA_API_URL}/AllTeams?key=${process.env.REACT_APP_SPORTS_DATA_API_KEY}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then((res) => res.json())
    .then((responseJson) => {
      if (!_.isNull(responseJson) && !_.isEmpty(responseJson)){
        // setAllteams(responseJson);
        let teamOption = [];
        _.map(responseJson, (team) => {
          teamOption.push({value: team.TeamID, label: team.FullName, key: team.Key})
        })  
        setTeamOptions(teamOption);
      }      
    }).catch(error => {
        console.log(error);
    })
  }  

  useEffect(() => {
    if(_.isUndefined(userProfile) || _.isNull(useProfile) || _.isEmpty(userProfile)) {
      history.push('/');
    } 
    getAllNews();
    getAllTeams();
  }, []);

  const getAllTeamPlayers = async (item) => {  
    fetch(`${process.env.REACT_APP_SPORTS_DATA_API_URL}/PlayersBasic/${item.key}?key=${process.env.REACT_APP_SPORTS_DATA_API_KEY}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((res) => res.json())
      .then((responseJson) => {
        if (!_.isNull(responseJson) && !_.isEmpty(responseJson)){
          let playerOption = [];
          _.map(responseJson, (player) => {
            playerOption.push({value: player.PlayerID, label: player.Name})
          })  
          setPlayerOptions(playerOption);
        }      
      }).catch(error => {
          console.log(error);
      })
  }  
  
  useEffect(() => {
    // selectPlayerRef.current.select.clearValue();
    if (!_.isNull(selectedTeam)){
      setSelectedPlayer(null)
      setPlayerOptions(null)
      getAllTeamPlayers(selectedTeam);
    }

  }, [selectedTeam])

  useEffect(() => {
    console.log(newsRecords)
  }, [newsRecords])

  useEffect(() => {
    // setNewsRecords(null)
    getAllNews();
  }, [startDate, endDate, selectedTeam, selectedPlayer])  

  const sortDataByKey = (key, order) => {
    setSortBy({ ...sortBy, [key]: order });
    if (key === 'date') {
      newsRecords.data.sort(sort_by(key, order === 'DESC' ? true : false, key === 'displayName' ? (a) => a.toLowerCase() : parseFloat))
      let data = {}
      data.data = newsRecords.data
      setNewsRecords(data)
    } else {
      newsRecords.data = _.orderBy(newsRecords.data, [c => c.newspriority], 
        order === 'DESC' ? 'desc' : 'asc')
        let data = {}
        data.data = newsRecords.data
        setNewsRecords(data)

    }
  }

  const deleteNews = async () => {

    if(_.isEmpty(newsId) || _.isNull(newsId)) {
      notify.show('News id is not defined', "danger", 5000);
      return 
    }

    await fetch(`${process.env.REACT_APP_NODE_API}/api/news/deleteNews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': !_.isUndefined(userProfile) && !_.isNull(userProfile) ? `Bearer ${userProfile.token}` : null,
      },
      body: JSON.stringify({id:newsId})
    })
    .then((res) => res.json())
    .then((responseJson) => {
      if (responseJson.status === 'success' && !_.isEmpty(responseJson.data)) {
        // setAllteams(responseJson);
        notify.show('News deleted successfully', "success", 5000);
        setNewsRecords(null)
        getAllNews();
      } else {
        notify.show('Something went wrong try again later', "error", 5000);
      }    
    }).catch(error => {
        console.log(error);
    })
  }

  const resetForm = () => {
    setStartDate(new Date(new Date().getFullYear() - (new Date().getMonth() > 0 ? 0 : 1), (new Date().getMonth() - 1 + 12) % 12, 1));
    setEndtDate(new Date());
    setSelectedPlayer(null);
    setPlayerOptions(null);
    setSelectedTeam(null)
  }

  console.log('newsRecords-->', newsRecords)

  return (
    <Fragment>
      {
          isLoading &&
          <Loader />
      }

       <div className="container-fluid px-3" id="dashboard-section">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center filter-section mt-3">
                <div className="d-flex align-items-center">
                  <label className="box-description text-start me-2">Select Date</label>
                  <div className="date-picker-box position-relative">
                    <DatePicker selected={startDate} onChange={(date) => { setStartDate(date) }}  maxDate={endDate} dateFormat="MMM d, yyyy"/>
                  </div>
                  <label className="date-text px-1">to</label>
                  <div className="date-picker-box position-relative">
                    <DatePicker selected={endDate} onChange={(date) => setEndtDate(date)} dateFormat="MMM d, yyyy" minDate={startDate} />
                  </div>
                  <Select 
                    classNames={{
                      control: () =>
                        'react-form-select ms-2',
                      }}  
                    placeholder="Team Name" 
                    options={teamOptions} 
                    defaultValue={{ label: "Team Name", value: '', key: '' }} 
                    value={selectedTeam}
                    onChange={(selectedValue) => { !_.isNull(selectedValue) && selectedValue.value !== '' ? setSelectedTeam(selectedValue) : setSelectedTeam(null) }} 
                    isClearable={!_.isNull(selectedPlayer) ? false : true}
                    styles={{
                      option: (base) => ({
                        ...base,
                        fontSize: 12,
                        height: '100%',
                      }),
                    }}
                  />
                  <Select 
                    classNames={{
                      control: () =>
                        'react-form-select ms-2',
                    }} 
                    placeholder="Player Name" 
                    options={playerOptions} 
                    defaultValue={{ label: "Player Name", value: '' }} 
                    value={selectedPlayer}
                    onChange={(selectedValue) => setSelectedPlayer(selectedValue)} 
                    isClearable={true}
                    styles={{
                      option: (base) => ({
                        ...base,
                        fontSize: 12,
                        height: '100%',
                      }),
                    }}
                    isDisabled={((!_.isUndefined(selectedTeam) && !_.isEmpty(selectedTeam) && !_.isNull(selectedTeam)) ?  false : true )}
                  />
                  <div className="ms-2 reset-link" onClick={resetForm}> Reset </div>
                </div>
                <div className="ms-auto">
                  <a href="/news" className="btn btn-primary btn-add">Add News</a>
                </div>
              </div>
            </div>  
            { (!_.isEmpty(newsRecords) && !_.isNull(newsRecords)) && <div className="col-12">

             {(!_.isUndefined(newsRecords.data) && !_.isNull(newsRecords.data) && !_.isEmpty(newsRecords.data)) ?      
              <div className="col-12">
                <div className="table-responsive mt-3">
                  <table className="table rounded-table">
                    <thead className="table-light">
                      <tr>
                        <th style={{width: '10%'}}><div className="sort-enable" style={{ backgroundPosition: '92px center' }} onClick={() => { sortDataByKey('date', sortBy.date === 'DESC' ? 'ASC' : 'DESC') }}>FLA Date Time</div></th>
                        <th style={{width: '12%'}}><div className="sort-enable" style={{ backgroundPosition: '92px center' }} onClick={() => { sortDataByKey('displayName', sortBy.displayName === 'DESC' ? 'ASC' : 'DESC') }}>News Title</div></th>
                        <th style={{width: '30%'}}>Description</th>
                        <th style={{width: '10%'}}>Fantasy Impact</th>
                        <th style={{width: '10%'}}>Dynasty Impact</th>
                        <th style={{width: '10%'}}>Betting Impact</th>
                        <th style={{width: '5%'}}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        _.map(newsRecords.data, (row, index) => {
                          let description_array = row.body.split(/\n?\n/);
                          let total_length = description_array.length
                          let row_description = description_array.slice(0, -2)
                          return (
                            <tr key={index}>
                                <td className="date-time">
                                {(!_.isUndefined(row.newsType) && row.newsType === 2 ? (moment(row.date).tz('America/New_York').format("MMM D, h:mm a")) : (moment(row.updatedAt).tz('America/New_York').format("MMM D, h:mm a")))}
                                </td>
                                <td>
                                  {((!_.isUndefined(row.newspriority) && !_.isEmpty(row.newspriority) && !_.isNull(row.newspriority)) ? row.newspriority : '')}
                                </td>
                                <td className="text-wrap">{row_description}</td>
                                <td>

                                  {
                                    ((row.status === 0) || ((!_.isUndefined(row.fantasyimpact)) && !_.isUndefined(row.fantasyimpact.status) && row.fantasyimpact.title === '' &&  row.fantasyimpact.description === '')) ?
                                    <div className="d-flex">
                                          <a href={"/news/" + row._id} className="text-decoration-none"><img src="/images/icons/ic-add.svg" className="status-mark pe-1" alt="icon-add" /> <span className="primary-text fw-semibold">Add</span></a>
                                        </div>
                                      :
                                        (row.status === 1)  ?
                                          <div className="d-flex date-time">
                                            <img src="/images/icons/ic-draft.svg" className="status-mark pe-1" alt="icon-draft" /> Drafted <br/>{moment(row.updatedAt).tz('America/New_York').format("MMM D, h:mm a")}
                                          </div>
                                        :
                                        <div className="d-flex date-time">
                                          <img src="/images/icons/ic-tick.svg" className="status-mark pe-1" alt="icon-tick" /> {moment(row.updatedAt).tz('America/New_York').format("MMM D, h:mm a")}
                                        </div>
                                  }
                                  
                                </td>
                                <td>
                                {
                                    
                                    ((row.status === 0) || ((!_.isUndefined(row.dynastyimpact)) && !_.isUndefined(row.dynastyimpact.status) && row.dynastyimpact.title === '' &&  row.dynastyimpact.description === '')) ?
                                    <div className="d-flex">
                                        <a href={"/news/" + row._id} className="text-decoration-none"><img src="/images/icons/ic-add.svg" className="status-mark pe-1" alt="icon-add" /> <span className="primary-text fw-semibold">Add</span></a>
                                      </div>
                                    :
                                    ((row.status === 2) && ((!_.isUndefined(row.dynastyimpact)) && !_.isUndefined(row.dynastyimpact.status)) && row.dynastyimpact.title !== '' &&  row.dynastyimpact.description !== '') ?
                                      <div className="d-flex date-time">
                                        <img src="/images/icons/ic-tick.svg" className="status-mark pe-1" alt="icon-tick" /> {moment(row.updatedAt).tz('America/New_York').format("MMM D, h:mm a")}
                                      </div>
                                      :
                                      
                                      <div className="d-flex date-time">
                                        <img src="/images/icons/ic-draft.svg" className="status-mark pe-1" alt="icon-draft" /> Drafted <br/> {moment(row.updatedAt).tz('America/New_York').format("MMM D, h:mm a")}
                                      </div>
                                  }
                                </td>
                                <td>
                                {
                                    
                                    ((row.status === 0) || ((!_.isUndefined(row.bettingimpact)) && !_.isUndefined(row.bettingimpact.status) && row.bettingimpact.title === '' &&  row.bettingimpact.description === '')) ?
                                      <div className="d-flex">
                                         <a href={"/news/" + row._id} className="text-decoration-none"><img src="/images/icons/ic-add.svg" className="status-mark pe-1" alt="icon-add" /> <span className="primary-text fw-semibold">Add</span></a>
                                      </div>
                                    :
                                      (row.status === 1)  ?
                                        <div className="d-flex date-time">
                                          <img src="/images/icons/ic-draft.svg" className="status-mark pe-1" alt="icon-draft" /> Drafted <br/> {moment(row.updatedAt).tz('America/New_York').format("MMM D, h:mm a")}
                                        </div>
                                   :
                                      <div className="d-flex date-time">
                                        <img src="/images/icons/ic-tick.svg" className="status-mark pe-1" alt="icon-tick" /> {moment(row.updatedAt).tz('America/New_York').format("MMM D, h:mm a")}
                                      </div>
                                }
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <a href={"/news/" + row._id} className="text-decoration-none"><img src="/images/icons/ic-edit.svg" alt="edit-icon" className="action-icon pe-1" /></a>
                                    {
                                      row.status !== 2  &&
                                      <div onClick={() => {
                                        setNewsId(row._id);
                                        setShowModal(true);
                                      }}><img src="/images/icons/ic-delete.svg" alt="delete-icon" className="action-icon pe-1" /></div>
                                    }
                                  </div>
                                </td>
                            </tr>
                          )
                        })  
                      }
                    </tbody>
                  </table>
                </div>
              </div> 
            :  
              <div className="py-5 text-center container">
                <div className="row py-lg-5">
                  <div className="col-lg-6 col-md-8 mx-auto empty-box py-5">
                    <img src="/images/icons/gr-no-data-available.png" alt="no-data"/>
                    <div className="heading pt-4 pb-3">No Records Found</div>
                    <div className="box-description">Welcome to Betsperts News Dashboard, currently there is not enough data for overview. You can explore other tabs.</div>
                  </div>
                </div>  
              </div> 
             }
             </div>
            }
          </div>
      </div>

      <div>
          <Modal
              show={showModal}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
              >
              <Modal.Body>
                  <div className="py-4 px-5">
                      <div className="model-heading mb-3">Are you sure?</div>
                      <div className="modal-message">You want to delete this news. This action cannot be undone. </div>
                  </div>
                  <div className="text-center">
                      <Button variant="danger" className="btn-submit btn btn-danger" onClick={() => {setShowModal(false); deleteNews()}}>
                          Delete
                      </Button>
                      <Button variant="secondary" className="btn-default btn-submit ms-3" onClick={handleClose}>
                          Cancel
                      </Button>
                  </div>    
              </Modal.Body>
          </Modal>
      </div>
    </Fragment>  
  );
}
