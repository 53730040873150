import React from 'react'
import _ from 'lodash';
import useProfile from '../Auth/useProfile';


export default function Nav(props) {
    const { userProfile } = useProfile();

    return (
        <nav className="navbar navbar-expand bg-white">
            <div className="d-flex page-title">
                {(window.location.pathname).includes("news") ?
                    'Create News' :  (window.location.pathname).includes("settings") ? 'Settings' : 'Overview' }
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={props.handleClick}>
            <span className="navbar-toggler-icon"></span>
            </button>
            {(window.location.pathname).includes("news") ?
            <a className="back-icon" href="/dashboard"><img src="/images/icons/back.svg" alt="back-icon"/></a>
            :
                <form className="d-smm-inline-block d-none">
                    <div className="input-group-navbar input-group">
                        <input placeholder="Search" aria-label="Search" className="form-control" />
                        <button type="button" className="btn" >
                        <img src="/images/icons/ic-search.png" className="me-2" alt="search" />
                        </button>
                    </div>  
                </form>
            }
            <div className="navbar-collapse collapse">
            <div className="navbar-align navbar-nav">
                <div className="nav-item">
                <div className="d-sm-inline-block">
                    <div className="nav-link d-flex align-items-center">
                    <span className="user-title">{(!_.isUndefined(userProfile) && !_.isNull(useProfile) && !_.isEmpty(userProfile)) && (userProfile.first_name + ' ' + userProfile.last_name) }</span>
                    <img src="/images/user.png" className="user-avatar" alt="user-avatar"/>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </nav>
    )
}    